// import { computed, getCurrentInstance } from 'vue'

import { computed, getCurrentInstance } from '@vue/composition-api'

const useVModel = (props, propName) => {
    const vm = getCurrentInstance().proxy

    return computed({
        get() {
            return props[propName]
        },
        set(value) {
            vm.$emit(`update:${propName}`, value)
        },
    })
}

const getPercentage = (dataList, categoryTotal) => {
    if (!dataList.length || !categoryTotal) return 0
    const dataTotal = dataList.reduce((currTotal, item) => {
        currTotal += item
        return currTotal
    }, 0)

    // because of order of operations, this should calculate correctly
    return Math.round(categoryTotal / dataTotal * 100)
}


export {
    useVModel,
    getPercentage
}
