<template lang="pug">
    // Only display below div if theres no data for charts
    div
        div(class="mr-6 col-span-1")
            img(src="@/assets/images/dashboard-welcome.png" alt="Welcome to UltimateQ!" class="m-auto w-500 mb-4 lg:mb-0 lg:w-full")
        div.text-text-color
            h1.mb-4 Welcome to UltimateQ Dashboard!
            .mb-4 UltimateQ analyses your collected data and provides an insights report to help improve the quality of your practice.
            .mb-4  Select the date range above to load the Dashboard.
</template>

<script>
export default {
    name: 'WelcomeMessage'
}
</script>
