import { format, addDays, addMonths, differenceInDays, parse, isAfter, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const formatInTimeZone = (date, fmt = '', tz = 'UTC') => {
    if (!date) return ''
    return format(
        utcToZonedTime(date, tz),
        fmt,
        { timeZone: tz }
    )
}

const parseInTimeZone = (date, fmt = 'yyyy-MM-dd', tz = 'UTC') => {
    if (!date) return null //TODO: figure out a better thing to return
    const fn = tz === 'UTC' ? parseISO : parse
    const args = [date]
    if (tz === 'UTC') args.push(fmt, new Date())

    return utcToZonedTime(fn.call(this, ...args), tz)
}

const parseInUTC = (date, fmt = 'yyyy-MM-dd', tz = 'UTC') => {
    if (!date) return null //TODO: figure out a better thing to return
    const parsedDate = tz === 'UTC'
        ? parseISO(date)
        : parse(format(new Date(date), fmt), fmt, new Date())

    return parsedDate
}

const getLast7Days = () => {
    let result = []
    for (let i = 0; i < 7; i++) {
        const date = new Date()
        date.setDate(date.getDate() - i)
        result.unshift(format(date, 'dd MMM'))
    }

    return result
}

const getLast7DaysAsDate = () => {
    let result = []
    for (let i = 0; i < 7; i++) {
        const date = new Date()
        date.setDate(date.getDate() - i)
        result.unshift(date)
    }

    return result
}

const getDate30DaysAgo = () => {
    const date = new Date()
    date.setDate(date.getDate() - 30)
    return format(date, 'yyyy-MM-dd')
}

const getDateLabels = (startDate, endDate) => {
    let labels = []
    let index = 0
    let nextDay = ''
    let dateFormat = ''
    let formattedDate = ''
    const endDateCheck = new Date(endDate)
    const startDateCheck = new Date(startDate)
    const differenceDays = differenceInDays(endDateCheck, startDateCheck)
    const showByMonth = differenceDays > 60
    const showBy7days = differenceDays > 13 && differenceDays < 60

    dateFormat = showByMonth ? 'MMM yyyy' : 'MMM dd, yyyy'
    if (showBy7days) {
        let start = startDate
        let end = startDate

        while(isAfter(endDateCheck, new Date(end))) {
            const startDateParsed = parse(start, 'yyyy-MM-dd', new Date())
            const dateToCheck = addDays(new Date(start), 7)
            end = isAfter(dateToCheck, endDateCheck) ? endDate : format(dateToCheck, 'yyyy-MM-dd')
            const endDateParsed = parse(end, 'yyyy-MM-dd', new Date())
            labels.push(`${format(startDateParsed, dateFormat)} ~ ${format(endDateParsed, dateFormat)}`)
            start = format(addDays(new Date(end), 1), 'yyyy-MM-dd')
        }
    } else {
        // if showing by month, then remove the 'day' component of the date since date is in format: yyyy-MM-dd
        if (showByMonth) endDate = endDate.substring(0, endDate.length - 3)

        while (endDate != nextDay) {
            if (showByMonth) nextDay = format(addMonths(startDateCheck, index), 'yyyy-MM')
            else nextDay = format(addDays(startDateCheck, index), 'yyyy-MM-dd')

            if (showByMonth) formattedDate = format(addMonths(startDateCheck, index), dateFormat)
            else formattedDate = format(addDays(startDateCheck, index), dateFormat)
        
            labels.push(formattedDate)
            index++
        }
    }

    return { 
        labels: labels,
        format: dateFormat
    }
}

export {
    getLast7Days,
    getLast7DaysAsDate,
    getDate30DaysAgo,
    getDateLabels,
    formatInTimeZone,
    parseInTimeZone,
    parseInUTC
}
