<template lang="pug">
    // Only display below div if theres no data for charts
    div.text-text-color
        div(class="mr-6 col-span-1")
            img(src="@/assets/images/charts-coming.png" alt="Welcome to SurveyCloud!" class="px-6 m-auto w-500 mb-4 lg:mb-0 lg:w-full")
        div
            h1.mb-4 You have no charts......yet!
            .mb-4 Once you receive survey submissions charts will display here.
</template>

<script>
export default {
    name: 'NoChartDisplay'
}
</script>
