<template lang="pug">
    div
        //div(v-if="hasData")
        div
            .flex.items-center.justify-between.mb-4
                h1.text-black
                    | {{title}}

                v-btn(
                    class=""
                    depressed
                    color="primary"
                    raised
                    @click="gotToDetails"
                )
                    | View more stats

                    fa-icon(
                        icon="chevron-right"
                        class="ml-2"
                    )

            //div(class="mt-2 grid grid-cols-1 lg:grid-cols-3 gap-4")
            div(
                class="mt-2 grid grid-cols-1 lg:grid-cols-4 gap-4"
            )
                div(class="col-span-2")
                    .my-4
                        BarChart(
                            v-if="!recentSubmissionsLoading"
                            :height="240"
                            :title="recentSubmissionsTitle"
                            :chartData="recentSubmissions.chartData"
                            :label="recentSubmissions.label"
                            :scope="recentSubmissions.scope"
                            :showDataLabels="true"
                        )
                        .flex.items-center.justify-center.h-20.w-full(v-else)
                            Loader(
                                class="w-12 h-12"
                            )
                //div(class="col-span-1")
                //    .my-4
                //        BarChart(
                //            :height="240"
                //            :title="averageResponseScores.title"
                //            :chartData="averageResponseScores.chartData"
                //            :label="averageResponseScores.label"
                //            :scope="averageResponseScores.scope"
                //        )
                div(class="col-span-2")
                    .my-4
                        BarChart(
                            v-if="!totalResponsesLoading"
                            :height="240"
                            :title="totalResponses.title"
                            :chartData="totalResponses.chartData"
                            :label="totalResponses.label"
                            :scope="totalResponses.scope"
                        )
                        .flex.items-center.justify-center.h-20.w-full(v-else)
                            Loader(
                                class="w-12 h-12"
                            )

</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import {
    AVERAGE_RESPONSE_SCORES_COLOURS,
    RECENT_SUBMISSIONS_COLOUR,
    SURVEY_TOTAL_RESPONSE_COLOURS
} from '@/common/constants'
import cloneDeep from 'clone-deep'
import { getLast7Days, getLast7DaysAsDate } from '@/modules/dateHelpers'
import { useLazyQuery } from '@vue/apollo-composable'
import { GET_STATS } from '@/graphql/queries/getStats.gql'
import camelcaseKeys from 'camelcase-keys'
import { logErrorMessages } from '@vue/apollo-util'
import { GET_FORM_ANSWERS_BY_FORM_SET } from '@/graphql/queries/getFormAnswers.gql'
import { format, isAfter, isEqual, startOfDay, subDays, subMonths } from 'date-fns'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import BarChart from '@/components/charts/BarChart.vue'
import { getPercentage } from '@/modules/formHelpers'
import Loader from '@/assets/loader.svg'

const { useState, useActions } = createNamespacedHelpers('app')

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'SurveySummary',
    components: { BarChart, Loader },
    emits: ['has-data', 'loading'],
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    setup({ title }, { emit, root }) {
        const { tenantId, dashboardFilter } = useGetters(['tenantId', 'dashboardFilter'])
        const totalSurveyNum = ref(0)
        const totalNewSurveys = ref(0)
        const recentSubmissionsLoading = ref(true)
        const totalResponsesLoading = ref(false)
        const firstLoad = ref(true)

        const { constants } = useState(['constants'])
        const surveyAnswers = ref([])
        const titleRef = ref(title)
        const today = new Date()
        const fiveDaysAgo = subDays(today, 5)
        const sixMonthsAgo = subMonths(today, 6)
        const router = root.$router
        const surveysCount = ref(null)
        const formSetId = computed(() => dashboardFilter.value.formSetId)

        const options = {
            responsive: true,
            maintainAspectRatio: false
        }
        const last7Days = getLast7Days()
        const last7DaysList = getLast7DaysAsDate()
        const defaultChartData = {
            title: '',
            chartData: {
                labels: '',
                datasets: [
                    {
                        backgroundColor: [],
                        data: [],
                        barThickness : 33
                    }
                ],
                originalLabels: []
            }
        }

        const recentSubmissions = ref({...defaultChartData})
        const recentSubmissionsTitle = computed(() => {
            return `Surveys Returned - ${surveysCount.value ?? '0'} surveys in last 7 days`
        })
        recentSubmissions.value.scope = {
            min: 0
        }
        recentSubmissions.value.chartData.labels = [...last7Days]
        recentSubmissions.value.originalLabels = recentSubmissions.value.chartData.labels
        recentSubmissions.value.chartData.datasets[0].backgroundColor = RECENT_SUBMISSIONS_COLOUR
        recentSubmissions.value.label = (tooltipItem, data) => {
            const total = data.datasets[0].data[tooltipItem.index]
            return `${total} ${total > 1 ? 'submissions' : 'submission'}`
        }

        const totalResponses = ref({...defaultChartData})
        totalResponses.value.title = 'Answers - Total Returned Surveys'
        totalResponses.value.scope = {
            min: 0
        }
        totalResponses.value.chartData.datasets[0].backgroundColor = [...SURVEY_TOTAL_RESPONSE_COLOURS]
        totalResponses.value.label = (tooltipItem, data) => {
            const total = data.datasets[0].data[tooltipItem.index]
            const label = totalResponses.value.originalLabels[tooltipItem.index]
            const percentage = getPercentage(data.datasets[0].data, total)
            return `${total} ${label} (${percentage}%)`
        }

        const getFormAnswerByFormSetData = computed(() => {
            return {
                tenantId: tenantId.value,
                formSetId: formSetId.value,
                updatedAt: format(sixMonthsAgo, 'yyyy-MM-dd')
            }
        })
        const getStatsData = computed(() => {
            return {
                tenantId: tenantId.value,
                formSetId: formSetId.value
            }
        })
        const {
            onResult,
            onError,
            load: loadAnswerStats,
            refetch: refetchAnswerStats
        } = useLazyQuery(GET_FORM_ANSWERS_BY_FORM_SET, getFormAnswerByFormSetData)

        const handleResult = ({ data }) => {
            const chartData = resetChartData(recentSubmissions.value.chartData)
            const formAnswers = camelcaseKeys(data?.formAnswers || [], { deep: true })
            totalSurveyNum.value = formAnswers.length
            totalNewSurveys.value = formAnswers.filter(fa => isAfter(new Date(fa.updatedAt), fiveDaysAgo)).length
            const recentSubmissionData = []

            for (const day of last7DaysList) {
                const currentCheck = startOfDay(day)
                recentSubmissionData.push(formAnswers.filter(
                    fa => isEqual(startOfDay(new Date(fa.updatedAt)), currentCheck)).length
                )
            }
            chartData.datasets[0].data = [...recentSubmissionData]

            surveysCount.value = recentSubmissionData.length
            recentSubmissions.value.chartData = {...chartData}
            recentSubmissionsLoading.value = false
            emitValues()
        }

        onResult(handleResult)
        onError(error => {
            logErrorMessages(error)
        })

        const getSurveyAnswers = (first, _) => {
            surveyAnswers.value = first.filter(c => c.group.toLowerCase() === 'survey_answer')
        }
        if (constants?.value.length) getSurveyAnswers(constants.value)

        watch(() => [...constants.value], getSurveyAnswers)

        // const {
        //     onResult: getStatsOnResult,
        //     onError: getStatsOnError,
        //     load: loadGetStats,
        //     refetch: refetchGetStats
        // } = useLazyQuery(GET_STATS, () => getStatsData.value)

        // const getStatsHandleResult = ({ data }) => {
        //     const chartData = resetChartData(totalResponses.value.chartData)
        //     totalResponses.value.originalLabels = []
        //
        //     if (!data) {
        //         totalResponses.value.chartData = {...chartData}
        //         emitValues()
        //         return
        //     }
        //     const statsRes = camelcaseKeys(data?.statsResponse?.stats)
        //     console.log('========== chart data values found', statsRes, data?.statsResponse)
        //     chartData.datasets[0].data = Object.values(statsRes.totalByQuestionHeader || 0)
        //
        //     const labels = []
        //     const originalLabels = []
        //     statsRes.questionsHeaders.map(q => {
        //         const surveyAnswerType = surveyAnswers.value.find(
        //             ag => Number(ag.value) === Number(q)
        //         )
        //         const originalLabel = surveyAnswerType?.text || q
        //         const label = originalLabel.length > 5 ? `${originalLabel.substring(0, 7)}...` : originalLabel
        //
        //         originalLabels.push(originalLabel)
        //         labels.push(label)
        //     })
        //     chartData.labels = labels
        //     totalResponses.value.originalLabels = originalLabels
        //
        //     totalResponses.value.chartData = {...chartData}
        //     totalResponsesLoading.value = false
        //     emitValues()
        // }
        //
        // getStatsOnResult(getStatsHandleResult)
        //
        // getStatsOnError(error => {
        //     console.log('error that happened')
        //     logErrorMessages(error)
        // })

        const hasData = computed(() => {
            const recentHasData = checkHasData(recentSubmissions.value)
            const totalHasData = checkHasData(totalResponses.value)
            return recentHasData || totalHasData
        })

        const isLoading = computed(() => {
            return totalResponsesLoading.value && recentSubmissionsLoading.value
        })

        const checkHasData = (chartOptions) => {
            // return chartOptions.chartData?.datasets[0]?.data.reduce((total, curr) => total + curr, 0) > 0
            return !!chartOptions.chartData?.datasets[0]?.data.length
        }

        const gotToDetails = () => {
            router.push({
                name: 'FormDetail',
                params: {
                    formSetId: formSetId.value
                },
                query: {
                    n: titleRef.value
                }
            })
        }

        const emitValues = () => {
            emit('has-data', hasData.value)
            emit('loading', isLoading.value)
        }

        const resetChartData = (chartData) => {
            const data = {...chartData}
            // data.labels = []
            data.datasets[0].data = []
            return data
        }

        const setCallParams = () => {
            // getFormAnswerByFormSetData.value = {
            //     tenantId: tenantId.value,
            //     formSetId: formSetId.value,
            //     updatedAt: format(sixMonthsAgo, 'yyyy-MM-dd')
            // }
            // getStatsData.value = {
            //     tenantId: tenantId.value,
            //     formSetId: formSetId.value,
            // }
        }

        watch(tenantId, (nextValue, prevVal) => {
            if (nextValue && nextValue !== prevVal) {
                totalResponses.value.chartData = resetChartData(totalResponses.value.chartData)
                recentSubmissions.value.chartData = resetChartData(recentSubmissions.value.chartData)
                if (!firstLoad.value) {
                    setCallParams()
                    refetchAnswerStats()
                    // refetchGetStats()
                }
            }
        })

        watch(formSetId, (newFormSetId) => {
            if (newFormSetId) {
                if (firstLoad.value) {
                    loadAnswerStats()
                    // loadGetStats()
                    firstLoad.value = false
                } else {
                    refetchAnswerStats()
                    // refetchGetStats()
                }
            }
        }, { immediate: true })

        if (firstLoad.value) {
            setCallParams()
        }

        return {
            options,
            recentSubmissions,
            recentSubmissionsTitle,
            recentSubmissionsLoading,
            totalResponsesLoading,
            totalResponses,
            hasData,
            gotToDetails,
            checkHasData
        }
    }
}
</script>
